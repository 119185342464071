import Flatpickr from "stimulus-flatpickr";

export default class extends Flatpickr {
  static targets = ["startDate", "endDate"];

  static values = {
    maxDate: String,
    minDate: String,
    altFormat: String,
  };

  connect() {
    this.config = {
      ...this.config,
      wrap: true,
      allowInput: true,
      altFormat: this.altFormatValue,
      altInput: true,
      time_24hr: true,
      mode: "range",
      enableTime: true,
      dateFormat: "Y-m-d H:i",
    };

    super.connect();
    this.handleInitialValue();
  }

  change(selectedDates, _, instance) {
    const { dateFormat } = instance.config;
    const formattedDates = selectedDates.map((date) => instance.formatDate(date, dateFormat));

    this.toggleClearButton(formattedDates);
    const [from, to] = formattedDates;

    this.startDateTarget.value = from || "";
    this.endDateTarget.value = to || "";
  }

  handleInitialValue() {
    const dates = [this.startDateTarget.value, this.endDateTarget.value];
    this.toggleClearButton(dates);
    this.fp.setDate(dates);
  }

  toggleClearButton([from, to]) {
    if (to || from) {
      this.element.querySelector("button[data-clear]")?.classList.remove("invisible");
    } else {
      this.element.querySelector("button[data-clear]")?.classList.add("invisible");
    }
  }
}
